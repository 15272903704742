.whtex-total-container {
  background-color: #f5f6fa;
  border-radius: 12px;
  padding: 16px 16px 16px 16px;
  margin-top: 40px;
  display: flex;
  align-items: center;

  .whtex-total-title {
    .MuiTypography-root {
      font-weight: bold;
    }

  }

  .whtex-totals {
    background-color: #f5f6fa;
    border-radius: 12px;

    display: flex;
    align-items: center;

    .whtex-total-box {
      margin-right: 24px;

      .whtex-total-box-title {
        color: var(--color-primary);
      }

      &.whtex-total-index {
        align-self: end;
        line-height: initial;
      }

      &.whtex-total-amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        //align-items: flex-start;
      }
    }
  }

  .whtex-total-buttons {
    display: flex;
    flex-direction: row;

    .MuiButtonBase-root{
      text-transform: none;
    }

    .whtex-total-action-sell {
      margin-right: 8px;
    }
  }
}
.whtex-total-modal-buy-table {
  margin-bottom: 32px;

  .MuiGrid-container{
    align-items: center;

    .MuiInputBase-root{
      background-color: #ffffff;


    }
  }
}