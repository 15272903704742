.bank-tab-container {
    margin-bottom: 20px;

    .MuiButtonBase-root.MuiTab-root.Mui-selected {
        color: #000;
    }
    
    .MuiTabs-root {
        border-radius: 12px 12px 0 0;

        .tabs {
            color: #353535;
            opacity: 0.5;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 1px;

            &.Mui-selected {
                background-color: #fff;
                font-weight: bold;
                opacity: 1;
            }
        }

        .bank-tab-buy {
            &.Mui-selected {
                color: black;
            }
        }

        .bank-tab-sell {
            &.Mui-selected {
                color: black;
            }
        }

        .MuiTabs-indicator {
            background-color: black;
        }
    }
}