*div {
  outline: 0;
  box-sizing: border-box!important;
}
.bg-light {
  background-color: var(--color-secondary-bg);
}
p {
  margin-bottom: 15px;
}
.table-header-bg {
  background-color: #32b1d04f !important;
}
.originator-row-bg {
  background-color: #48b3ce48 !important;
}
.MuiDialog-root .MuiDialogActions-root.space_between_button {
  justify-content: space-between;
  padding: 24px;
}
.MuiDataGrid-cell p.MuiTypography-body2 {
  margin-bottom: 0;
  font-size: 15px;
}
.panel-header {
  width: 100%;
  padding: 10px 16px !important;
  background-color: var(--color-secondary-bg);
  text-transform: uppercase;
  .MuiCardHeader-title {
    font-size: 14px;
    letter-spacing: 1px;
  }
}

.flex-column {
  @media screen and (min-width: 1200px) and (max-width: 1650px) {
    flex-direction: column;
  }
}

.tot-whtx-12 {
  @media screen and (min-width: 1200px) and (max-width: 1650px) {
    font-size: 12px !important;
  }
}

.column-label {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--color-secondary-text);
}
.page-title-container {
  padding: 30px 24px 40px 5%; 
  position: relative;
  top: -16px;
  left: -16px;
  width: calc(100% + 32px);
  background-color: #f6f7f9;
  
  @media(min-width: 1200px) {
    padding: 30px 16px 40px 5%;
    top: -24px;
    left: -24px;
    width: calc(100% + 48px);
  }
  h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
}