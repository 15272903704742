.home-buttons{
  display: flex;
  flex-direction: column;

  button{
    margin-bottom: 30px;
    width: 100%;
  }
}
.full-page-video-home-container{
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100vh;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  .full-page-video-home{
    width: 100vw;
    object-fit: cover;
  }
  &.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }
}