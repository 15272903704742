.trade_tab_modal_container {
  .MuiDialog-paper {
    background: transparent linear-gradient(180deg, #F5F6FA 0%, #A4D3F1 100%) 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 32px;

    .trade_tab_modal_title_bold {
      font-weight: bold;

      &.trade_tab_modal_title_grid {
        margin-bottom: 8px;
      }
    }

    .trade_tab_modal_title {
      text-align: center;
      margin-bottom: 24px;
    }

    .trade_tab_modal_grid {
      text-align: center;
      padding-left: 16px;
      margin-bottom: 16px;

      .trade_tab_modal_grid_data {
        background-color: var(--color-secondary);
        border-radius: 18px;
        margin-bottom: 24px;

        .trade_tab_modal_grid_data_second_row {
          align-items: center;


          .trade_tab_modal_grid_data_item {
            padding-top: 8px;
            padding-bottom: 8px;
          }
        }
      }
    }
  }

  .trade_tab_modal_amount{
    flex-basis: 30%;
  }

  .trade_tab_modal_amount_data{
    text-align: center;
  }

  .trade_tab_modal_order_description {
    margin-bottom: 24px;

    .trade_tab_modal_order_description_container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

    }

    .trade_tab_modal_order_description_title {
      margin-bottom: 8px;
    }

    .trade_tab_modal_od {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      margin-bottom: 16px;
      flex-basis: 33%;

      span {
        margin-right: 8px;
      }

      .trade_tab_modal_od_buy {
        text-transform: uppercase;

      }

      .trade_tab_modal_od_qty {
        font-size: 16px;
      }
    }
  }

  .trade_tab_modal_message {
    margin-bottom: 24px;

    .trade_tab_modal_message_title {
      margin-bottom: 8px;
    }

    .trade_tab_modal_message_text {
      font-size: 16px;
    }
  }

  .trade_tab_modal_last_grid {
    display: flex;

    .MuiGrid-container {
      align-items: center;
    }
  }

  .trade_tab_modal_field_error {
    color: #FF0000FF;
    font-weight: bold;
  }

}