.trade-tab-form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 0 0 12px 12px;
  padding: 20px 10px;
  @media screen and (max-width: 1500px) {
    .MuiFormHelperText-root {
      top: 40px;
      }
      
      .trade-tab-form-row-title {
        font-size: 0.7em;
    }
  }
  @media screen and (min-width: 1500px) and (max-width: 2000px) {
    .MuiFormHelperText-root {
      top: 40px
    }
                                                                .trade-tab-form-row-title {
                                                                  font-size: 1.5em;
                                                                }
  }
  @media screen and (min-width: 2000px) {
    .MuiFormHelperText-root {
      top: 33px
    }
                                                                .trade-tab-form-row-title {
                                                                  font-size: 1em;
                                                                }
  }
  .trade-tab-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;

    @media screen and (min-width: 1200px) and (max-width: 1650px) { 
      flex-direction: column;
    }
    
    .trade-tab-form-row-input, .trade-tab-form-row-select {
      background-color: #ffffff;
      border-radius: 6px;
      margin-bottom: 4px ;

      .MuiInputBase-input, .MuiSelect-select {
        height: 35px;
        font-size: 14px;
        padding: 0 10px;
        display: flex;
        align-items: center;
      }
    }

    .trade-tab-form-row-title {
      flex-basis: 90%;
      text-transform: uppercase;
      font-size: 0.7em;
      letter-spacing: 1px;
      font-weight: 700;
      padding-right: 5px;
    }

    .trade-tab-form-row-middle-title {
      font-weight: bold;
    }

    &.trade-tab-form-row-button {
      justify-content: center;
      margin-top: 20px;

      .MuiButtonBase-root {
        text-transform: capitalize;
      }
    }
  }

  .trade-tab-form-row-description {
    padding: 0 8px 8px 8px;
    font-size: 11px;

    &-italics {
      font-style: italic;
    }
  }
}

.action-type {
  overflow: hidden;
}