.order-book-container {
  .order-book-title-data {
    margin-bottom: 15px;
  }

  .order-book-data-row {
    display: flex;
    text-align: center;
    justify-content: space-around;

    &.order-book-data-title-row {
      align-items: flex-end;
      padding-bottom: 15px;


      .order-book-data-title {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: var(--color-secondary-text);
        text-transform: uppercase;
      }
    }

    .order-book-data {
      flex-basis: 16.6%;
      z-index: 2;
      padding: 3px 0;
      font-size: 14px;

      &-buy {
        color: #009639;
      }

      &-sell {
        color: #fe4d6a;
      }
    }

    &.order-book-total-data-row {
      padding: 0 8px;
      position: relative;

      .order-book-total-data-row-color {

        height: 100%;
        width: 66.4%;
        position: absolute;
        display: flex;

        .box-color{
          border-radius: 12px;

        &.green {
          background: #009639;
          color: #ffffff;
          height: 100%;
          position: absolute;
          left: 0;

        }

        &.red {
          background: #fe4d6a;
          color: #ffffff;
          height: 100%;
          position: absolute;
          right: 0;

        }
          &.over{
            z-index: 1;
          }
        }
      }

    }

  }
}