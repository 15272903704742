#news-list-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
#news-list-container > div {
    width: calc(50% - 10px);
    margin-bottom: 20px;
    border-radius: 6px;
    background-color:  #fff;
    box-shadow: 0 1px 2px rgb(214 219 226 / 50%), 0 1px 2px rgb(214 219 226 / 50%);
    padding: 30px;
}