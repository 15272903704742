.active-order-container {
  .active-order-data-row {
    display: flex;
    text-align: center;
    justify-content: space-around;
    margin-bottom: 10px;
    font-size: 14px;

    &.active-order-data-row-button {
      margin: 20px 0 0 0;
      justify-content: flex-end;

      .MuiButtonBase-root {
        text-transform: capitalize;
      }
    }

    &.active-order-data-title-row {
      align-items: flex-end;
      margin-bottom: 20px;

      .active-order-data-title {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .active-order-data {
      flex-basis: 16.6%;

    }
  }
  .MuiSvgIcon-root {
    color: var(--color-secondary-text);
  }
}