.main {
  .login-form-email-code{
    display: flex;
    justify-content: center;
    margin-bottom: 32px;

    .login-form-code-input{
      margin-bottom: 16px;
    }


  }
  .login-form {
    background-color: #f5f6fa;
    min-width: 30vw;
    padding: 40px;
    border-radius: 12px;

    .MuiTextField-root {
      margin-bottom: 40px;
      background-color: #ffffff;
      border-radius: 6px;
    }


  }
  .login-card {
    background-color: var(--color-primary);
    color: #ffffff;
    width: 60%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @media  screen and (max-width: 1024px) {
      width: 100%;
    }

    &.warning {
      background-color: var(--color-warning);
      color: var(--color-warning-text);
    }
    .resend-button-card{
      padding-bottom: 24px;
    }
  }

  .back-bottom-container {
    //min-width: 30vw;
    //margin-left: auto;
    //margin-right: auto;
    //padding: 20px 0;
    //border-radius: 12px;
    //text-align: right;

    .button-link{
      justify-content: flex-end;
      margin-top: 40px;
      &:hover{
        background-color: transparent;
      }

      .link{
        color: #626262;
        text-transform: lowercase;
        text-decoration: none;
      }
    }
  }
}