.full-page {
  background-image: url("../assets/bg-login-whtexch.png");
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  padding: 32px 16px 16px 16px;


  .main {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    .logo{
      margin-bottom: 60px;
    }
  }
}

.hidden{
  display: none;
}