
.page-title-container.trade {
  display: flex;
  padding: 0 !important;
  .header-title-content {
    padding: 30px 5% 40px 5%;
    background-color: #1f2937;
    color: #90a0b7;
  }
}
.button-content {
  margin: auto 0;

  button {
    margin: 0 10px 0 10px;
  }
}
.trade-container {
  display: flex;
  .aft-tab-stats-chart-orderbook-container {
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    flex-grow: 5;
    flex-basis: 20%;

    @import "./../../components/AftSummary";

    .tab-chart-orderbook-container {
      display: flex;


      .trade-tab-stats-container {
        display: flex;
        flex-direction: column;
        padding-right: 8px;

        .MuiTabs-flexContainer {
          display: flex;
          justify-content: space-between;
        }
      }

      .trade-chart-orderbook-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        padding-left: 8px;
      }
    }
  }

  .totalwhtex-activeorder-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 20%;
    justify-content: space-between;

    .whtex-total-container {
      display: block;

      .whtex-total-title {
        margin-bottom: 32px;
      }

      .whtex-totals {
        justify-content: space-between;
        margin-bottom: 32px;
      }

      .whtex-total-box-action {
        display: flex;

        .MuiButtonBase-root {
          padding: 6px 80px;
        }
      }
      .whtex-total-buttons{
        justify-content: flex-end;
      }
    }
  }
}
