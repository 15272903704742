.trade-stats {
  .trade-stats-row-container {
    opacity: 1;

    .trade-stats-row-first-second {
      margin-bottom: 24px;
    }
    .trade-stats-row {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      &.first{
        color: #009639;
      }
      &.second{
        color:#fe4d6a
      }
    }
  }
}