.MuiDataGridContainer {
  width: 100%;
  height: 372px;

  .MuiDataGrid-virtualScroller {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .MuiDataGrid-virtualScrollerContent:not(.MuiDataGrid-virtualScrollerContent--overflowed) {
    .MuiDataGrid-row {
      width: calc(100% - 15px);
    }
  }
}