.aft-total-box .MuiCardContent-root:last-child {
  padding: 0 !important;
}
.aft-totals{
  .aft-total-box{
    margin-right: 16px;
    .aft-total-box-title{
      background-color: #f6f7f9;
      padding: 10px 20px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: var(--color-secondary-text);
    }

    .aft-total-box-value{
      padding: 20px 20px;

      .MuiTypography-root{
        font-size: 24px;
        font-weight: 700;
        text-align: center;
      }
    }

    &.aft-total-exp-pl{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}