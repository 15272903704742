.trade-tab-container{
  margin-bottom: 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-image: linear-gradient(rgba(255, 255, 255, 0.01), rgba(255, 255, 255, 0.01));

  .MuiTabs-root{
    border-radius: 12px 12px 0 0;

    .MuiTabs-flexContainer{
    display: flex;
    justify-content: space-between;

      .trade-tab{
        color: #353535;
        opacity: 0.5;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;

        &-buy{
          flex-basis: 50%;
        }
        &-sell{
          flex-basis: 50%;
        }
        &.Mui-selected{
          background-color: #fff;
          font-weight: bold;
          opacity: 1;
        }
      }
      .trade-tab-buy {
        &.Mui-selected {
          color: green;
        }
      }
      .trade-tab-sell {
        &.Mui-selected {
          color: red;
        }
      }
    }
  }
}