.aft-summary-container{
  width: 100%;
  max-width: 930px;
  text-align: center;
  .aft-column-container {
    width: 100%;
    height: 100%;
    padding: 0 5% 0 0;
    display: flex;
    justify-content: space-between;
  }
  .aft-column {    
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    &>div {
      width: 100%;
    }
    .aft-column-title{
      margin-bottom: 10px;
      text-align: center;
      font-size: 13px;
      font-weight: bold;
      color: var(--color-secondary-text);
      text-transform: uppercase;
    }
    .aft-column-data p{
      font-size: 25px;
    }
    &:first-child {
      padding: 0 5%;
      background-color: #f8e71c;
      
      .aft-column-title{
        font-size: 18px;
      }
       p {
         font-size: 30px;
       }
    }
  }
}