.panel-header, .panel-header-light {
  .aft-button-report {
    margin-left: 16px;
  }
}

.panel-header-light {
  .MuiCardHeader-title {
    font-weight: normal;
  }
}